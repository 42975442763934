































































import { popoStore } from '@/stores/popo-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({ default: true }) showTitle
  @Prop({ default: () => [] }) nfts
  @Prop({
    default: () => {
      return {}
    },
  })
  decoratedPopoInfo

  @Prop({ default: true }) isNotExistNft
  popoStore = popoStore

  get itemPerRow() {
    return this.$vuetify.breakpoint.lgAndUp ? 2 : this.$vuetify.breakpoint.smOnly ? 2 : 4
  }

  attachNfts() {
    this.popoStore.changeOpenPopoAttachmentDialog(true, this.decoratedPopoInfo.id)
  }
  detachSingleNft(nft) {
    this.popoStore.changeOpenPopoDetachSingleDialog(true, this.decoratedPopoInfo.id, nft?.nftId)
  }

  get totalRows() {
    const realLength = this.nfts.length < 4 ? this.nfts.length + 1 : 4
    return Math.ceil(realLength / this.itemPerRow)
  }
}
